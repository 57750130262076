import '../App.css';
import playLauncherPNG from '../images/play-launcher.png'
import playClientPNG from '../images/play-client.png'
import playZippedPNG from '../images/play-zipped.png'
import navLogoPNG from '../images/nav-logo.png'
import footerLogoPNG from '../images/footer-logo.png'
import logoMobilePNG from '../images/logo-mobile.png'
import footerDiscordSVG from '../images/svg/footer-discord.svg'
import footerTwitterSVG from '../images/svg/footer-twitter.svg'
import footerYouTubeSVG from '../images/svg/footer-youtube.svg'
import { Link } from './HomePage'

function DownloadPage() {
    return <body style={{ backgroundColor: "black" }}>
    <header className="home-hero" style={{ backgroundColor: "rgba(0, 0, 0, .70)", "background-blend-mode": "darken" }}>
      <NavBar />
      <DownloadEnlight />
      <EnlightDownloads />
      <ExtraEnlightDownloads />
    </header>
    <div>
      <Footer />
      <div class="footer__cc py-3 mt-0">
          <div class="container">
              <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                  <p class="small text-faded">Copyright © 1337 Enlight. All rights reserved.</p>
              </div>
          </div>
      </div>
    </div>
  </body>
}

// function BlackTopBar() {
//   return <div className="top-nav-bar py-3">
//     <div className="container d-flex flex-row align-items-center justify-content-between">
//       <Link to="https://discord.gg/8u9Ekp7pvG" target="_blank" className="d-flex flex-row align-items-center justify-content-start text-faded">
//         <img src="/images/svg/nav-discord-icon.svg" className="me-2" />
//         <p className="small">Join us on Discord</p>
//       </Link>
//       <Link href="/play" className="d-flex flex-row align-items-center justify-content-start text-faded">
//         <img src="/images/svg/nav-download-icon.svg" className="me-2" />
//         <p className="small">Start Playing Enlight!</p>
//       </Link>
//     </div>
//   </div>
// }

function NavBar() {
  return <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container container--narrow">
  
      <Link class="navbar-brand d-flex d-lg-none me-auto ms-0" to="/"><img src={logoMobilePNG} style={{ height: "170px", margin: "-40px -15px" }} class="d-flex d-lg-none" lazy/></Link>
  
      <button class="navbar-toggler d-flex d-lg-none align-items-center justify-content-center p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarMenu">
        <ul class="navbar-nav mx-auto text-uppercase">
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/">Home</Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/"></Link> 
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/play">Play</Link>
          </li>
          <li class="nav-item d-none d-lg-flex">
            <Link class="nav-link navbar-brand" to="/"><img src={navLogoPNG} style={{ margin: "-15px -15px" }} class="d-none d-lg-flex"/></Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <a class="nav-link" href="https://enlight.everythingrs.com/vote">Vote</a>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to=""></Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <a class="nav-link" href="https://discord.gg/8u9Ekp7pvG" target="_blank">Discord</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
}

function DownloadEnlight() {
 return <div class="container mt-5">
   <div class="d-flex flex-column align-items-center justify-content-center text-center">
   <h2 class="text-uppercase text-white text-shadow my-0 weight-600">DOWNLOAD</h2>
   <h1 class="hero-heading text-uppercase text-shadow my-0 text-orange weight-600">Enlight</h1>
   <p class="hero-desc text-shadow mb-0">Select a client below and start playing</p>
   </div>
 </div>
}

function EnlightDownloads() {
  return   <main>
  <div class="container container">
    <div class="main-container my-4">
      <div class="main-container__glow--top"></div>
      <div class="main-container__inner">
          
        
      <div class="row gx-2 gy-2 gy-md-0">
  <div class="col-4 col-md-4">
      <div class="card">
          <div class="card-header d-flex align-items-center justify-content-center">
              <h4 class="mb-0 text-center text-white weight-600">Launcher .jar</h4>
          </div>
          <a href="https://github.com/Kovlez/game-client/raw/main/Enlight%20Launcher.jar" target="_blank" class="card-body p-3 pb-0" file>
              <div class="position-relative d-block m-auto mt-2" style={{"max-height": "240px"}}>
                  <img src={playLauncherPNG} class="d-block m-auto h-100" lazy/>
              </div>
              <div class="button py-2 px-3 w-100 d-block text-center mb-1 mt-3">Download</div>
          </a>
          <p class="mb-3 small text-uppercase text-center text-light-gray">1MB</p>
      </div>
  </div>
  <div class="col-4 col-md-4">
      <div class="card">
          <div class="card-header d-flex align-items-center justify-content-center">
              <h4 class="mb-0 text-center text-white weight-600">MSI Installer (Windows)</h4>
          </div>
          <a href="https://github.com/Kovlez/game-client/raw/main/Enlight-Installer.msi" target="_blank" class="card-body p-3 pb-0" file>
              <div class="position-relative d-block m-auto mt-2" style={{"max-height": "200px"}}>
                  <div class="position-absolute top-0 start-50 translate-middle small recommended-box" styles={{ background: "white"}}>Recommended</div>
                  <img src={playLauncherPNG} class="d-block m-auto h-100" lazy/>
              </div>
              <div class="button py-2 px-3 w-100 d-block text-center mb-1 mt-3">Download</div>
          </a>
          <p class="mb-3 small text-uppercase text-center text-light-gray">42MB</p>
      </div>
  </div>
  <div class="col-4 col-md-4">
      <div class="card">
          <div class="card-header d-flex align-items-center justify-content-center">
              <h4 class="mb-0 text-center text-white weight-600">Zipped Launcher .exe with Java</h4>
          </div>
          <a href="https://github.com/Kovlez/game-client/raw/main/Enlight.zip" target="_blank" class="card-body p-3 pb-0" file>
              <img src={playZippedPNG} class="d-block m-auto mt-2" style={{"max-height": "200px"}} lazy/>
              <div class="button py-2 px-3 w-100 d-block text-center mb-1 mt-3">Download</div>
          </a>
          <p class="mb-3 small text-uppercase text-center text-light-gray">41MB</p>
      </div>
  </div>
</div>
        

      </div>
    </div>
  </div>
</main>
}


function ExtraEnlightDownloads() {
    return   <main>
    <div class="container container" style={{ "z-index": 3, "max-width": "864px" }}>
      <div class="main-container my-4">
        <div class="main-container__glow--top"></div>
        <div class="main-container__inner">
            
          
        <div class="row gx-2 gy-2 gy-md-0">
    <div class="col-6 col-md-6">
        <div class="card">
            <div class="card-header d-flex align-items-center justify-content-center">
                <h4 class="mb-0 text-center text-white weight-600">Client .jar (Any OS)</h4>
            </div>
            <a href="https://github.com/Kovlez/game-client/raw/main/Enlight-Client.jar" target="_blank" class="card-body p-3 pb-0" file>
                <div class="position-relative d-block m-auto mt-2" style={{"max-height": "200px"}}>
                    <img src={playLauncherPNG} class="d-block m-auto h-100" lazy/>
                </div>
                <div class="button py-2 px-3 w-100 d-block text-center mb-1 mt-3">Download</div>
            </a>
            <p class="mb-3 small text-uppercase text-center text-light-gray">45MB</p>
        </div>
    </div>
    <div class="col-6 col-md-6">
        <div class="card">
            <div class="card-header d-flex align-items-center justify-content-center">
                <h4 class="mb-0 text-center text-white weight-600">Zipped Cache</h4>
            </div>
            <a href="https://github.com/Kovlez/game-client/raw/main/.Enlight-Cache.zip" target="_blank" class="card-body p-3 pb-0" file>
                <img src={playZippedPNG} class="d-block m-auto mt-2" style={{"max-height": "200px"}} lazy/>
                <div class="button py-2 px-3 w-100 d-block text-center mb-1 mt-3">Download</div>
            </a>
            <p class="mb-3 small text-uppercase text-center text-light-gray">97MB</p>
        </div>
    </div>
  </div>
          
  
        </div>
      </div>
    </div>
  </main>
  
}

function Footer() {
    return <footer class="footer mt-3 mt-lg-5 mb-0">
        <div class="footer__inner py-5 pt-md-6">
            <div class="container">
                <ul class="footer__menu d-none d-sm-flex flex-row align-items-center justify-content-center">
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline weight-700">Home</Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline"></Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/play" class="h5 text-uppercase text-white no-underline weight-700">Play</Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><a href="https://enlight.everythingrs.com/vote" class="h5 text-uppercase text-white no-underline weight-700">Vote</a></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline"></Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><a href="https://discord.gg/8u9Ekp7pvG" target="_blank" class="h5 text-uppercase text-white no-underline weight-700">Discord</a></li>
                </ul>
                <div class="footer__divider my-4 my-md-5 d-none d-sm-block"></div>
                <div class="w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between">
                    <Link to="/">
                        <img style={{ height: "140px", margin: "-50px 0px" }} src={footerLogoPNG} lazy />
                    </Link>
                    <div class="d-flex flex-row align-items-center justify-content-center gap-4 mt-3 mt-sm-0">
                        <a href="https://discord.gg/8u9Ekp7pvG" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerDiscordSVG} lazy />
                        </a>
                        <Link to="/" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerTwitterSVG} lazy />
                        </Link>
                        <Link to="/" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerYouTubeSVG}lazy />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default DownloadPage