import '../App.css';
import navLogoPNG from '../images/nav-logo.png'
import footerLogoPNG from '../images/footer-logo.png'
import logoMobilePNG from '../images/logo-mobile.png'
import { Link as RouterLink } from 'react-router-dom'
import { Link as MaterialUILink } from '@material-ui/core'
import footerDiscordSVG from '../images/svg/footer-discord.svg'
import footerTwitterSVG from '../images/svg/footer-twitter.svg'
import footerYouTubeSVG from '../images/svg/footer-youtube.svg'

export const Link = (props) => <MaterialUILink class={props.class} component={RouterLink} to={props.to}>{props.children}</MaterialUILink>

function HomePage() {
  return <body style={{ backgroundColor: "black" }}>
    <header className="home-hero" style={{ backgroundColor: "rgba(50, 10, 10, .22)", "background-blend-mode": "darken" }}>
      <NavBar />
      <StartPlayingNow />
    </header>
    {/* <main class="pb-3 pb-lg-5" style={{ "z-index": 10, position: "relative", height: "10px"}}></main> */}
    <div>
        <Footer />
        <div class="footer__cc py-3 mt-0">
            <div class="container">
                <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <p class="small text-faded">Copyright © 1337 Enlight. All rights reserved.</p>
                </div>
            </div>
        </div>
    </div>
  </body>
}

// function BlackTopBar() {
//   return <div className="top-nav-bar py-3">
//     <div className="container d-flex flex-row align-items-center justify-content-between">
//       <a href="https://discord.gg/8u9Ekp7pvG" target="_blank" className="d-flex flex-row align-items-center justify-content-start text-faded">
//         <img src="/images/svg/nav-discord-icon.svg" className="me-2" />
//         <p className="small">Join us on Discord</p>
//       </a>
//       <Link href="/play" className="d-flex flex-row align-items-center justify-content-start text-faded">
//         <img src="/images/svg/nav-download-icon.svg" className="me-2" />
//         <p className="small">Start Playing Enlight!</p>
//       </Link>
//     </div>
//   </div>
// }

function NavBar() {
  return <nav class="navbar navbar-expand-lg navbar-dark py-3">
    <div class="container container--narrow">
  
      <Link class="navbar-brand d-flex d-lg-none me-auto ms-0" to="/"><img src={logoMobilePNG} style={{ height: "170px", margin: "-40px -15px" }} class="d-flex d-lg-none" lazy/></Link>
  
      <button class="navbar-toggler d-flex d-lg-none align-items-center justify-content-center p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="collapse navbar-collapse" id="navbarMenu">
        <ul class="navbar-nav mx-auto text-uppercase">
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/">Home</Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/"></Link> 
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to="/play">Play</Link>
          </li>
          <li class="nav-item d-none d-lg-flex" style={{ margin: "-15px -15px" }}>
            <Link class="nav-link navbar-brand" to="/"><img src={navLogoPNG} class="d-none d-lg-flex"/></Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <a class="nav-link" href="https://enlight.everythingrs.com/vote">Vote</a>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <Link class="nav-link" to=""></Link>
          </li>
          <li class="nav-item py-2 py-lg-0">
            <a class="nav-link" href="https://discord.gg/8u9Ekp7pvG" target="_blank">Discord</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
}

function StartPlayingNow() {
    return <div class="container my-4 my-lg-5 py-4 py-lg-5">
        <div class="d-flex flex-column align-items-center justify-content-center text-center">
            <h2 class="text-uppercase text-white text-shadow my-0 weight-600">The Adventure Awaits</h2>
            <h1 class="hero-heading text-uppercase text-orange text-shadow my-0 weight-600">START PLAYING!</h1>
            <p class="hero-desc text-shadow mb-0">Download and start playing on Enlight right now!</p>
            <Link to="/play" class="hero-button hero-button--play h4 text-uppercase d-flex justify-content-center align-items-center text-center mt-3 mt-lg-5 m-auto text-white no-underline">
                Play Now
            </Link>
        </div>
    </div>
}

function Footer() {
    return <footer class="footer mt-3 mt-lg-5 mb-0">
        <div class="footer__inner py-5 pt-md-6">
            <div class="container">
                <ul class="footer__menu d-none d-sm-flex flex-row align-items-center justify-content-center">
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline weight-700">Home</Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline"></Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/play" class="h5 text-uppercase text-white no-underline weight-700">Play</Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><a href="https://enlight.everythingrs.com/vote" class="h5 text-uppercase text-white no-underline weight-700">Vote</a></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><Link to="/" class="h5 text-uppercase text-white no-underline"></Link></li>
                    <li class="footer__menu--item footer__menu--divider d-none d-md-inline-flex"></li>
                    <li class="footer__menu--item py-3 py-md-0 px-3 px-md-4 px-lg-5"><a href="https://discord.gg/8u9Ekp7pvG" target="_blank" class="h5 text-uppercase text-white no-underline weight-700">Discord</a></li>
                </ul>
                <div class="footer__divider my-4 my-md-5 d-none d-sm-block"></div>
                <div class="w-100 d-flex flex-column flex-sm-row align-items-center justify-content-center justify-content-sm-between">
                    <Link to="/">
                        <img style={{ height: "140px", margin: "-50px 0px" }} src={footerLogoPNG} lazy />
                    </Link>
                    <div class="d-flex flex-row align-items-center justify-content-center gap-4 mt-3 mt-sm-0">
                        <a href="https://discord.gg/8u9Ekp7pvG" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerDiscordSVG} lazy />
                        </a>
                        <Link to="/" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerTwitterSVG} lazy />
                        </Link>
                        <Link to="/" target="_blank" class="footer__social d-flex align-items-center justify-content-center">
                            <img src={footerYouTubeSVG}lazy />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
}

export default HomePage